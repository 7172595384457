import { makeStyles, Theme } from "@material-ui/core";
import { gds_theme, gds_typo, gds_service } from "@ui/goono/styles/theme";

const useStyles = makeStyles((MuiTheme: Theme) => ({
  root: {
    width: "100%",
  },
  label: {
    marginBottom: 8,
    color: gds_theme.gray_7_text,
    ...gds_typo.b1_m,
    [MuiTheme.breakpoints.down(gds_theme.break_sm)]: {
      ...gds_typo.h5_mobile,
    },
  },
  labelDot: {
    display: "flex",
    "&::after": {
      content: '""',
      marginLeft: 4,
      marginTop: 6,
      width: 6,
      height: 6,
      borderRadius: "50%",
      backgroundColor: gds_theme.primary,
    },
  },
  inputBase: {
    borderRadius: gds_theme.round_3,
    border: `1px solid ${gds_theme.gray_3}`,
    color: gds_theme.gray_7_text,
    backgroundColor: gds_theme.white,
    transition: ".25s",
    ...gds_theme.notosans,
    ...gds_typo.b2_r,
    "&:hover, &.Mui-focused": {
      borderColor: gds_theme.gray_5_body,
    },
    "&.Mui-focused": {
      boxShadow: `0 0 4px ${gds_theme.primary}`,
    },
    "&.Mui-disabled": {
      background: gds_theme.gray_2,
    },
  },
  border: { borderColor: gds_theme.gray_5_body },
  size_lg: {
    padding: "12px 16px",
    height: 56,
  },
  size_lg_ta: {
    padding: "12px 16px",
    minHeight: 56,
  },
  size_md: {
    padding: "8px 16px",
    height: 48,
  },
  size_md_ta: {
    padding: "12px 16px",
    minHeight: 48,
  },
  size_sm: {
    padding: "4px 16px",
    height: 40,
  },
  size_sm_ta: {
    padding: "12px 16px",
    minHeight: 40,
  },

  input: {
    padding: 0,
    height: "100%",
    "&::placeholder": {
      color: gds_theme.gray_5_body,
      opacity: 1,
      ...gds_typo.b3_r,
    },
  },
  disabled: {
    backgroundColor: `${gds_theme.gray_2} !important`,
    borderColor: `${gds_theme.gray_2} !important`,
    color: gds_theme.gray_5_body,
  },
  errorMode: {
    "& $inputBase": {
      borderColor: gds_theme.danger,
    },
    "& $helpertext": {
      color: gds_theme.danger,
    },
    "& $inputBase.Mui-focused": {
      boxShadow: `0 0 4px ${gds_theme.danger}`,
    },
  },
  bottomBox: {
    marginTop: 8,
  },
  helpertext: {
    marginRight: 24,
    color: gds_theme.gray_7_text,
    ...gds_typo.b4_r,
  },
  counterBox: {
    marginLeft: "auto",
    flex: 1,
    maxWidth: 107,
    flexShrink: 0,
  },
  counter: {
    ...gds_typo.b4_r,
  },
  counter_current: { color: gds_theme.gray_7_text },
  counter_max: { color: gds_theme.gray_7_text },
  iconBase: {
    width: 24,
    height: 24,
    fill: "currentColor",
  },
  startIcon: {
    marginRight: 8,
  },
  endIcon: {
    marginLeft: 16,
  },
  resetBtn: {
    margin: "0 8px",
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: `2px solid ${gds_service.primary_50}`,
    color: gds_service.primary_50,
    background: gds_theme.primary,

    "& svg": {
      width: 16,
      height: 16,
    },
  },
}));

export default useStyles;
