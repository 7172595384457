import React from "react";
import loadable from "@loadable/component";
import { doDiaLogAction } from "src/redux/store/reducers/dialog";
import { DiaLogActionKind } from "src/redux/store/reducers/dialog";
import { DiaLogStateStatus } from "src/redux/store/reducers/dialog";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store/reducers";

const Dialog = loadable(() => import("../../components/public/modals/Dialog"));

export default function GoonoDialogManager() {
  const dialog = useSelector((state: RootState) => state.dialog);
  const dispatch = useDispatch();

  if (dialog.state.status !== DiaLogStateStatus.OPENED) return <></>;
  return (
    <Dialog
      open
      type={
        dialog.state.status === DiaLogStateStatus.OPENED
          ? dialog.state.type
          : undefined
      }
      title={
        dialog.state.status === DiaLogStateStatus.OPENED
          ? dialog.state.title
          : undefined
      }
      confirmBtnMsg={
        dialog.state.status === DiaLogStateStatus.OPENED
          ? dialog.state.confirmBtnMsg
          : undefined
      }
      cancelBtnMsg={
        dialog.state.status === DiaLogStateStatus.OPENED
          ? dialog.state.cancelBtnMsg
          : undefined
      }
      bodyMsg={
        dialog.state.status === DiaLogStateStatus.OPENED
          ? dialog.state.bodyMsg
          : undefined
      }
      bodyMsgHighlightStyleMap={
        dialog.state.status === DiaLogStateStatus.OPENED
          ? dialog.state.bodyMsgHighlightStyleMap
          : undefined
      }
      bodyNode={
        dialog.state.status === DiaLogStateStatus.OPENED
          ? dialog.state.bodyNode
          : undefined
      }
      iconColor={
        dialog.state.status === DiaLogStateStatus.OPENED
          ? dialog.state.iconColor
          : undefined
      }
      iconNode={
        dialog.state.status === DiaLogStateStatus.OPENED
          ? dialog.state.iconNode
          : undefined
      }
      onCancel={() => {
        dialog.state.status === DiaLogStateStatus.OPENED &&
          dialog.state.onCancel?.();

        doDiaLogAction(dispatch, {
          kind: DiaLogActionKind.TRY_CLOSE,
        });
      }}
      onConfirm={() => {
        dialog.state.status === DiaLogStateStatus.OPENED &&
          dialog.state.onConfirm?.();

        doDiaLogAction(dispatch, {
          kind: DiaLogActionKind.TRY_CLOSE,
        });
      }}
    />
  );
}
